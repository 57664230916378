<template>
  <v-row align="center">
    <v-col cols="12" lg="12" sm="12" align="center">
      <v-alert color="primary" dark icon="mdi-cash" border="left" prominent>
        <div class="font-weight-bold">
          Sin tarjeta de crédito, sin problema. Paga en efectivo.
        </div>
      </v-alert>
    </v-col>
    <v-col cols="12" md="12" sm="12" align="center">
      <v-col cols="12" lg="6" sm="12">
        <v-text-field label="Id" :value="order.ocId" outlined readonly></v-text-field>

        <v-text-field label="Nombre" :value="order.nombreContacto" outlined readonly></v-text-field>

        <v-text-field label="Telefono" :value="order.telefono" outlined readonly></v-text-field>

        <v-text-field label="Correo electronico" :value="order.email" outlined readonly></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" sm="12">
        <v-btn
          color="primary"
          dark
          :elevation="0"
          @click="() => finish()"
          block
          rounded
        >
          Finalizar
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
  props: {
    order: {
      type: Object,
      default: {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      charge: {},
      isSended: false,
    };
  },
  methods: {
    finish() {
      let ocId = this.order.ocId;

      let url = `api/v1`;

       if (this.isAuthenticated) {
        url += `/methods/cash/${ocId}`;
      } else {
        url += `/public/methods/cash/${ocId}`;
      }    
      axios
        .post(url)
        .then(() => {
          this.$router.push(`/orders/${ocId}`).catch(() => {});
        })
        .catch(() => {
          this.$dialog({
            title: "Error",
            text: "Hubo un error al establecer el metodo de pago en efectivo, por favor intentelo de nuevo.",
          }).then(() => {
            // DOES NOTHING
          });
        });
    },
  },
};
</script>